import React from 'react'
import { Layout, MainNav, MainTitle } from '@components'

const title = 'Dapur Kayangan'
const subtitle = 'Jom Lawati laman-laman kami'

export default function Home() {
  return (
    <Layout>
      <section className='mx-auto mt-5'>
        <MainTitle title={title} subtitle={subtitle} />
        <MainNav />
      </section>

      <div className='flex'>
        <div className='flex-1 max-w-4xl mx-auto px-10 pt-10 flex flex-col justify-center'>
          <section>
            <div style={{ height: '450px' }}>
              <iframe
                frameBorder='0'
                loading='lazy'
                allowFullScreen
                title='Buku Edu Online Woocipz'
                className='w-full h-full shadow-xl'
                src='https://www.youtube.com/embed/PrAcYXaGyus?rel=0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              />
            </div>
            <div className='mb-5' />
          </section>
        </div>
      </div>
      <div className='mb-10' />
    </Layout>
  )
}
